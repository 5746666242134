
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import BulletPoint from "@/app/ui/components/icons/modules/bullet-point.vue";
import formatPriceWithoutCurrency from "@/app/infrastructures/misc/common-library/FormatPriceWithoutCurrency";
import DonutChart from "@/app/ui/components/donut-chart/index.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { OptionsClass } from "@/domain/entities/MainApp";
import randomColor from "@/app/infrastructures/misc/common-library/RandomColor";
import { DataCorporateDashboard } from "@/domain/entities/Dashboard";
import { ProductController } from "@/app/ui/controllers/ProductController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";

@Options({
  components: {
    BulletPoint,
    CardDashboardClient,
    DonutChart
  }
})
export default class DeliveryDistributionPerProductCard extends Vue {
  mounted() {
    this.getDeliveryDistributionProduct();
  }
  formattedNumber(value: number) {
    return formatPriceWithoutCurrency(value);
  }
  isLoading = true;

  async getDeliveryDistributionProduct() {
    this.isLoading = true;
    const response = await DashboardController.getCorporateDashboardDeliveryData(
      {
        path: "/delivery/product",
        sttCategory: "",
        cache: true
      }
    );

    this.productFiltered(response);
    this.isLoading = false;
  }

  productFiltered(data: DataCorporateDashboard) {
    const productDistribution = data.distributionProduct;
    const productDistributionFiltered = Object.entries(productDistribution).map(
      ([key, value]) => {
        return {
          name: key,
          value: value
        };
      }
    );
    this.chartProductMapping(productDistributionFiltered);
  }

  chartProductMapping(productDistributionFiltered: any) {
    for (const product of this.productData) {
      const nameOfProductDistributionFiltered = productDistributionFiltered.find(
        (item: OptionsClass) => item.name === product.name
      );

      const value = nameOfProductDistributionFiltered
        ? nameOfProductDistributionFiltered?.value
        : 0;
      const color = randomColor().toUpperCase();
      this.chartColor.push(color);
      this.chartData.push(value);
      this.productDistribution.push({
        name: product.name.toLowerCase(),
        value: value,
        color: color
      });
    }
  }

  chartData: any = [];
  chartColor: any = [];
  productDistribution: any = [];

  get productData() {
    if (IS_SENDER_ACCOUNT) return ProductController.productSenderList;
    return BookingController.productsDelivery.data;
  }
}
