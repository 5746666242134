
import { Vue, prop, Options } from "vue-class-component";
import VueApexCharts from "vue3-apexcharts";

class Props {
  isShowTotal = prop<boolean>({
    type: Boolean,
    default: false
  });
  chartTitle = prop<string>({
    type: String,
    default: ""
  });
  chartSeries = prop<any>({
    default: []
  });
  chartColor = prop<any>({
    default: []
  });
  chartHeight = prop<string>({
    type: String,
    default: "250"
  });
  chartWidth = prop<string>({
    type: String,
    default: "100%"
  });
  showPercentage = prop<boolean>({
    type: Boolean,
    default: false
  });
  totalValue = prop<number>({
    type: Number,
    default: 0
  });
  fontSizeValue = prop<string>({
    type: String,
    default: "22px"
  });
  fontSizeName = prop<string>({
    type: String,
    default: "16px"
  });
  ableToHover = prop<boolean>({
    type: Boolean,
    default: false
  });
  size = prop<string>({
    type: String,
    default:"60%"
  });
}

@Options({
  components: {
    VueApexCharts
  }
})
export default class DonutChart extends Vue.with(Props) {
  series = this.$props.chartSeries;
  chartOptions = {
    chart: {
      type: "donut"
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    states: {
      hover: {
        filter: {
          type: "none"
        }
      }
    },
    colors: this.$props.chartColor,
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
            name: {
              offsetY: 16
            },
            value: {
              offsetY: -15,
              fontWeight: "600",
              fontSize: this.$props.fontSizeValue,
              fontFamily: "Poppins"
            },
            total: {
              show: this.$props.isShowTotal,
              showAlways: true,
              label: this.$props.chartTitle,
              fontSize: this.$props.fontSizeName,
              fontWeight: "200",
              fontFamily: "Poppins",
              color: "#78787A",
              formatter: this.$props.showPercentage
                ? function(val: any) {
                    const maxNumber = val.globals.seriesTotals[0];
                    const totalValue = val.globals.seriesTotals.reduce(
                      (a: any, b: any) => {
                        return a + b;
                      },
                      0
                    );
                    if (totalValue === 0) return "0 %";
                    return (
                      ((maxNumber / totalValue) * 100).toLocaleString("id", {
                        maximumFractionDigits: 2
                      }) + " %"
                    );
                  }
                : function(w: any) {
                    return w.globals.seriesTotals.reduce((a: any, b: any) => {
                      return a + b;
                    }, 0);
                  }
            }
          },
          size: this.size
        }
      }
    },
    legend: {
      show: false
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 205
          },
          legend: {
            show: false
          }
        }
      }
    ]
  };
}
